import React from "react";

import fb from "../img/fb.svg";
import ig from "../img/ig.svg";
import line from "../img/line.svg";
import yt from "../img/yt.svg";
import tt from "../img/tt.svg";

export default function Footer(props) {
  return (
    <section className="footer">
      <div className="bg-white shadow-[0_-4px_6px_-1px_rgb(0,0,0,0.1)] shadow-gray-300 relative z-[998]">
        <div className="container px-2 sm:px-12 lg:px-28 pt-5 pb-2 flex flex-col items-center sm:flex-row sm:justify-between sm:items-center">
          <div>
            <img
              src={require("../img/logo-wf.png")}
              alt="logo wf"
              id="logowf"
              className="w-1/3 block mx-auto sm:w-3/4 sm:ml-0 "
            />
          </div>
          <div className="w-full text-center sm:text-start sm:w-1/2 md:w-[40%] lg:w-1/2">
            <div className="text-2xl font-semibold">
              บริษัท เวิลด์ แฟร์ จำกัด
            </div>
            <div className="text-sm mb-0 sm:mb-4">
              1,3,5,7 ซ. ลาดพร้าว 128/1 ถ.ลาดพร้าว แขวงคลองจั่น เขตบางกะปิ
              กรุงเทพฯ 10240
            </div>
            <div className="font-semibold">
              <a href="tel:027311331">โทร : 02-731-1331</a>
            </div>
            <div className="font-semibold">
              <a href="mailto:operation@worldfair.co.th">
                Email : operation@worldfair.co.th
              </a>
            </div>
          </div>
          <div className="hidden md:block md:ml-auto">
            <ul className="list-disc">
              <li className="hover:text-[#ff0000]">
                <a href="/">หน้าแรก</a>
              </li>
              <li className="hover:text-[#ff0000]">
                <a href="/aboutus">เกี่ยวกับเรา</a>
              </li>
              <li className="hover:text-[#ff0000]">
                <a href="/ourservices">บริการของเรา</a>
              </li>
              <li className="hover:text-[#ff0000]">
                <a href="/calendar">ปฏิทินงานแสดงสินค้า</a>
              </li>
              <li className="hover:text-[#ff0000]">
                <a href="/news">ข่าวสาร</a>
              </li>
              <li className="hover:text-[#ff0000]">
                <a href="/joinus">สมัครงาน</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="px-5 md:px-20 lg:px-28 pt-6 pb-2 flex flex-col sm:flex-row sm:justify-between items-center border-t">
          <div className="order-last text-xs mt-4 sm:mt-0 sm:text-base sm:order-none ">
            สงวนสิทธิ์ &copy; 2023 บริษัท เวิลด์ แฟร์ จำกัด
          </div>
          <div className="flex gap-3 items-center">
            <div className="hidden sm:block">ติดตามเรา :</div>
            <div>
              <img
                className="w-full sm:w-3/4 md:w-full"
                src={fb}
                alt="fb"
                id="fblogo"
              />
            </div>
            <div>
              <img
                className="w-full sm:w-3/4 md:w-full"
                src={ig}
                alt="ig"
                id="iglogo"
              />
            </div>
            <div>
              <img
                className="w-full sm:w-3/4 md:w-full"
                src={line}
                alt="line"
                id="linelogo"
              />
            </div>
            <div>
              <img
                className="w-full sm:w-3/4 md:w-full"
                src={yt}
                alt="youtube"
                id="youtubelogo"
              />
            </div>
            <div>
              <img
                className="w-full sm:w-3/4 md:w-full"
                src={tt}
                alt="tiktok"
                id="tiktoklogo"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
