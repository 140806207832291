import React, { createContext, useState, useEffect } from "react";

import CustomerHistoryHeader from "./chdHeader";
import CustomerHistoryList from "./chdList";

export const dataContext = createContext();

export default function CustomerHistoryData() {
  const [customer, setCustomer] = useState({ customerID: "", Name: "" });

  const [hislist, setHislist] = useState([]);

  const initFilter = {
    during: false,
    sdate: "",
    edate: "",
    venue: [1, 3, 2, 10],
  };
  const [filter, setFilter] = useState(initFilter);

  useEffect(() => {
    //console.log(hislist);
  }, [hislist]);

  return (
    <dataContext.Provider
      value={{
        customerC: [customer, setCustomer],
        hislistC: [hislist, setHislist],
        filterC: [filter, setFilter],
      }}
    >
      <section id="customer-data">
        <div>
          <h1 className="text-xl my-3">Customer History Data</h1>
        </div>
        <CustomerHistoryHeader />
        <CustomerHistoryList />
      </section>
    </dataContext.Provider>
  );
}
