const Condition = () => (
  <div>
    <h4 className="text-xl font-medium">
      นโยบายความเป็นส่วนตัว (Privacy Policy)
    </h4>
    <p>
      บริษัท เวิลด์ แฟร์ จำกัด ( “บริษัทฯ”)
      ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคลของคุณ
      โดยนโยบายความเป็นส่วนตัวฉบับนี้ได้อธิบายแนวปฏิบัติเกี่ยวกับการเก็บรวบรวม
      ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล รวมถึงสิทธิต่างๆ ของเจ้าของข้อมูลส่วนบุคคล
      ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลของท่านตามนโยบายความเป็นส่วนตัวฉบับนี้
    </p>
    <h5 className="text-xl font-medium">การเก็บรวบรวมข้อมูลส่วนบุคคล </h5>
    <p>
      เราจะเก็บรวบรวมข้อมูลส่วนบุคคลที่ได้รับโดยตรงจากคุณผ่านช่องทาง ดังต่อไปนี้
    </p>
    <ul className="list-disc list-inside">
      <li>ชื่อ</li>
      <li>ที่อยู่</li>
      <li>ที่อยู่อีเมล</li>
      <li>เบอร์โทรศัพท์</li>
    </ul>
    <h5 className="text-xl font-medium">ประเภทข้อมูลส่วนบุคคลที่เก็บรวบรวม</h5>
    <ul className="list-disc list-inside">
      <li>
        ข้อมูลส่วนบุคคลที่สามารถระบุตัวตนได้ เช่น ชื่อ อายุ วันเกิด สัญญาชาติ
        เป็นต้น
      </li>
      <li>ข้อมูลการติดต่อ เช่น ที่อยู่ หมายเลขโทรศัพท์ อีเมล เป็นต้น</li>
      <li>ข้อมูลบัญชี เช่น บัญชีผู้ใช้งาน ประวัติการใช้งาน เป็นต้น</li>
      <li>
        หลักฐานแสดงตัวตน เช่น สำเนาบัตรประจำตัวประชาชน สำเนาหนังสือเดินทาง
        เป็นต้น
      </li>
      <li>
        ข้อมูลทางเทคนิค เช่น IP Address, Cookie ID, ประวัติการใช้งานเว็บไซต์
        (Activity Log) เป็นต้น
      </li>
    </ul>
    <h5 className="text-xl font-medium">การใช้คุกกี้ (Cookies)</h5>
    <p>
      ในบางกรณี บริษัทฯ มีการใช้ “คุกกี้” บนเว็บไซต์ของบริษัทฯ
      เช่นเดียวกับบริษัทอื่น
      คุกกี้เป็นข้อมูลที่เก็บบนเครื่องคอมพิวเตอร์ของท่านโดยเบราว์เซอร์ของท่านเพื่อบันทึกความสนใจของท่านในการเข้าใช้งานเว็บไซต์แต่ละแห่ง
      เมื่อท่านลงชื่อเข้าใช้งาน คุกกี้จะบอกให้บริษัทฯ ทราบว่า
      ท่านเคยเข้าใช้เว็บไซต์ของบริษัทฯ มาก่อน หรือเป็นผู้เข้าใช้รายใหม่ บริษัทฯ
      ใช้คุกกี้เพื่อช่วยให้บริษัทฯ
      ทราบรูปแบบการใช้งานเว็บไซต์ที่ท่านมีความสนใจเป็นพิเศษ เพื่อให้บริษัทฯ
      สามารถให้บริการแก่ท่านตามความประสงค์ได้เป็นอย่างดี ทั้งนี้
      ท่านสามารถเลือกตั้งค่าเบราว์เซอร์ของท่านให้ปิดการใช้คุกกี้
      หรือเลือกคำสั่งลบคุกกี้เฉพาะรายการหรือทั้งหมดในเครื่องคอมพิวเตอร์ของท่านได้โดยเปิดไฟล์ช่วยเหลือในเบราว์เซอร์ของท่าน
      อย่างไรก็ตาม หากท่านปิดการใช้คุกกี้บนเว็บเบราว์เซอร์ของท่าน
      ท่านอาจประสบปัญหาในการเข้าใช้งานหน้าเว็บไซต์บางหน้าและรูปแบบการใช้งานบางลักษณะบนเว็บไซต์นี้ได้
    </p>
    <h5 className="text-xl font-medium">
      วิธีการของบริษัทฯ ในการใช้ข้อมูลส่วนบุคคล
    </h5>
    <p>
      เป้าหมายหลักของบริษัท เวิลด์ แฟร์ จำกัด
      ในการเก็บรวบรวมข้อมูลส่วนบุคคลก็เพื่อให้แน่ใจว่า
      ผู้ชมงานแสดงสินค้าของบริษัทฯ รู้สึกพึงพอใจกับงานแสดงสินค้าของบริษัทฯ
      และให้บริการที่มีคุณภาพดีที่สุดแก่ผู้ชมงานแสดงสินค้าของบริษัทฯ
      ข้อมูลส่วนบุคคลของท่านจะไม่ถูกนำไปแบ่งปัน ขาย ให้ยืม
      หรือเปิดเผยด้วยวิธีการอื่นออกไปภายนอกบริษัทฯ (
      เว้นแต่ตามที่กฎหมายหรือระเบียบต่างๆ กำหนด )
      ในลักษณะอื่นใดที่แตกต่างไปจากที่ระบุไว้ในนโยบายความเป็นส่วนตัวฉบับนี้
      บริษัทฯ
      จะดำเนินมาตรการตามสมควรทุกวิถีทางเพื่อรักษาความมั่นคงปลอดภัยและความถูกต้องของข้อมูลส่วนบุคคลของท่าน
      รวมถึงการใช้รหัสผ่านเข้าถึงเครื่องคอมพิวเตอร์
      พื้นที่จัดเก็บข้อมูลที่มีการเข้ารหัส (lock-up cabinets) นโยบายบุคลากร
      บริษัทฯ
      ใช้มาตรการรักษาความมั่นคงปลอดภัยที่เหมาะสมเพื่อคุ้มครองข้อมูลส่วนบุคคล
      รวมถึงในกรณีที่บุคคลอื่นเป็นผู้ประมวลผลข้อมูลส่วนบุคคลแทนในนามของบริษัทฯ
    </p>
    <h5 className="text-xl font-medium">
      บริษัท เวิลด์ แฟร์ จำกัด อาจใช้ข้อมูลส่วนบุคคลที่ท่านให้ไว้
    </h5>
    <ul className="list-disc list-inside">
      <li>
        เพื่อประเมินผลกิจกรรมทางการตลาด การโฆษณา และเว็บไซต์ และ/หรือ อื่นๆ
        ที่บริษัทฯ ได้จัดทำขึ้น
      </li>
      <li>เพื่อวิเคราะห์ข้อมูลกลุ่มผู้ชมงาน</li>
      <li>เพื่อวิเคราะห์พฤติกรรมการบริโภค</li>
    </ul>
    <h5 className="text-xl font-medium">
      วิธีการที่บริษัทฯ อนุญาตให้ใช้ข้อมูลส่วนบุคคล
    </h5>
    <p>
      บริษัทฯ อาจอนุญาตให้บุคคลอื่นใช้ข้อมูลเกี่ยวกับท่านในบางกรณี
      รวมถึงกรณีดังต่อไปนี้
    </p>
    <p>
      <strong>การโฆษณาตามความสนใจ</strong> บริษัทฯ
      มีส่วนร่วมในการโฆษณาตามความสนใจ ( พฤติกรรมออนไลน์ ) กล่าวคือ
      บุคคลอื่นอาจมีการใช้เทคโนโลยี อาทิเช่น คุกกี้บนเบราว์เซอร์ของท่าน
      เพื่อระบุตัวบุคคลของท่านบนเว็บไซต์ของ บริษัทฯ
      เพื่อโฆษณาเกี่ยวกับผลิตภัณฑ์และบริการตามความสนใจของท่านโดยเฉพาะ
      ท่านอาจเห็นโฆษณาบนเว็บไซต์ของบริษัทฯ
      ในลักษณะที่สอดคล้องกับพฤติกรรมการเข้าดูหรือซื้อสินค้า
      หรือท่านอาจเห็นโฆษณาผลิตภัณฑ์ของบริษัทฯ
      บนเว็บไซต์อื่นตามพฤติกรรมของท่านในเว็บไซต์ต่างๆ
    </p>
    <p>
      <strong>บล็อก การโพสต์ข้อความออนไลน์ และทำคำรับรองสินค้า</strong> บริษัทฯ
      อาจเปิดให้ผู้ใช้สามารถแสดงความคิดเห็น โพสต์ข้อความ ทำคำรับรองสินค้า
      หรือข้อมูลอื่นก็ได้ หากท่านเลือกที่จะส่งข้อมูลดังกล่าวให้แก่บริษัทฯ
      ข้อมูลที่ท่านส่งอาจถูกนำไปเปิดเผยต่อสาธารณชน
      ข้อมูลที่ท่านให้ไว้ตามหัวข้อนี้อาจถูกอ่าน เก็บรวบรวม
      และนำไปใช้โดยบุคคลอื่นที่สามารถเข้าถึงข้อมูลดังกล่าวได้
    </p>
    <p>
      <strong>บุคคลอื่น</strong> บริษัทฯ
      อาจอนุญาตให้บุคคลอื่นที่มิได้ระบุชื่อข้างต้นนี้นำข้อมูลที่มิใช่ข้อมูลส่วนบุคคลไปใช้ได้
      ในกรณีดังกล่าว บริษัทฯ
      อาจผสมข้อมูลหรือทำให้ข้อมูลดังกล่าวไม่สามารถระบุตัวบุคคลได้ ทั้งนี้
      เพื่อให้บุคคลอื่นไม่สามารถเชื่อมโยงข้อมูลดังกล่าวมาถึงตัวท่าน
      เครื่องคอมพิวเตอร์ หรืออุปกรณ์ของท่านได้ การผสมข้อมูล หมายถึง บริษัทฯ
      จะผสมข้อมูลที่มิใช่ข้อมูลส่วนบุคคลของบุคคลต่างๆ เข้าด้วยกัน
      เพื่อให้ข้อมูลนั้นไม่เกี่ยวข้องกับบุคคลใดบุคคลหนึ่ง
      การทำให้ข้อมูลไม่สามารถระบุตัวบุคคลได้ หมายถึง บริษัทฯ
      พยายามลบหรือเปลี่ยนแปลงข้อมูลบางส่วนที่อาจใช้เชื่อมโยงข้อมูลกับบุคคลใดบุคคลหนึ่งได้
    </p>
    <p>
      <strong>การบังคับใช้กฎหมาย และกระบวนการทางกฎหมาย</strong> บริษัทฯ
      อาจแจ้งหน่วยงานบังคับใช้กฎหมายให้ทราบถึงกิจกรรมต่างๆ ที่บริษัทฯ
      มีเหตุผลให้เชื่อได้ว่ามิชอบด้วยกฎหมาย หรือที่บริษัทฯ
      มีเหตุผลให้เชื่อได้ว่าอาจเป็นประโยชน์แก่การสอบสวนเพื่อบังคับใช้กฎหมายกับกิจกรรมที่มิชอบด้วยกฎหมาย
      นอกจากนี้ บริษัทฯ
      สงวนสิทธิที่จะเผยแพร่ข้อมูลของท่านแก่หน่วยงานบังคับใช้กฎหมายกรณีที่บริษัทฯ
      มีดุลพินิจโดยลำพังเห็นว่า ท่านได้ฝ่าฝืนนโยบายต่างๆ ของบริษัทฯ
      หรือการเผยแพร่ข้อมูลของท่านเป็นการปกป้องสิทธิ ทรัพย์สิน
      หรือเพื่อความปลอดภัยของบริษัทฯ หรือบุคคลอื่น
    </p>
    <p>
      <strong>การอนุญาตให้ใช้ข้อมูลของท่านตามที่กฎหมายอนุญาต</strong> บริษัทฯ
      อาจอนุญาตให้บุคคลอื่นใช้ข้อมูลของท่านตามที่กฎหมายกำหนด หรืออนุญาต
      ซึ่งหมายรวมถึงการส่งข้อมูลของท่านให้แก่หน่วยราชการ
      หรือบุคคลอื่นตามหมายเรียก คำสั่งศาล กระบวนการอื่นตามกฎหมาย
      หรือตามที่บริษัทฯ เชื่อว่าจำเป็นแก่การใช้สิทธิของตนตามกฎหมาย
      แก้ต่างคดีความที่ฟ้องร้องกับบริษัทฯ หรือแก้ต่างคดีความที่บริษัทฯ
      มีดุลพินิจโดยลำพังว่าอาจมีการฟ้องร้องกับบริษัทฯ ได้
    </p>
    <p>
      ธุรกรรมทางธุรกิจ อาจมีบางกรณีที่บริษัทฯ ตัดสินใจขาย ซื้อ ควบรวม
      หรือปรับโครงสร้างกิจการของตนโดยวิธีการอื่นใด
      ซึ่งอาจเป็นเหตุให้ต้องเปิดเผยข้อมูลส่วนบุคคลแก่ผู้ที่ประสงค์หรือทำการซื้อขายจริง
      ในการนี้ บริษัทฯ
      มีแนวปฏิบัติในการปกป้องการเปิดเผยข้อมูลอย่างเหมาะสมในธุรกรรมประเภทต่างๆ
      ดังกล่าว
    </p>
    <h5 className="text-xl font-medium">
      ข้อมูลที่ท่านส่งทางเว็บไซต์ของบริษัทฯ
    </h5>
    <p>
      โดยการส่งข้อมูลทางเว็บไซต์ที่ดำเนินการโดยบริษัท เวิลด์ แฟร์ จำกัด
      ท่านต้องรับรองดังนี้ (1) ท่านเป็นเจ้าของข้อมูลนั้น (2)
      ข้อมูลนั้นถูกต้องและเป็นความจริง (3)
      ข้อมูลนั้นมิได้ก่อให้เกิดความเสียหายแก่บุคคลหรือนิติบุคคลใดๆ (4)
      ข้อมูลนั้นไม่เป็นการละเมิดลิขสิทธิ์ เครื่องหมายการค้า กรรมสิทธิ์
      หรือสิทธิในชื่อเสียงและความเป็นส่วนตัวของบุคคลอื่น (5)
      ข้อมูลนั้นไม่เป็นการฝ่าฝืนกฎหมาย กฎเกณฑ์ ข้อบัญญัติ หรือระเบียบใดๆ (6)
      ข้อมูลนั้นไม่ถือเป็นการหมิ่นประมาท สบประมาท ก่อให้เกิดความเกลียดชัง
      เหยียดเชื้อชาติหรือศาสนาใด หรือล่วงละเมิด หรือคุกคามบุคคลอื่น (7)
      ข้อมูลนั้นมิได้อ้างอิงถึงเว็บไซต์อื่น ที่อยู่อีเมล ข้อมูลติดต่อ
      หรือหมายเลขโทรศัพท์ (8)
      หากข้อมูลนั้นมีภาพเหมือนหรือชื่อที่สามารถระบุตัวบุคคลได้
      ท่านต้องได้รับอนุญาตจากบุคคลนั้นที่จะใช้ภาพเหมือน/ชื่อของบุคคลดังกล่าวเพื่อส่งข้อมูลนั้นด้วย
      และ (9) ข้อมูลนั้นไม่มีไวรัสคอมพิวเตอร์ หนอน
      หรือโปรแกรมหรือไฟล์ที่อาจก่อความเสียหายอื่นได้
    </p>
    <p>
      สำหรับข้อมูลที่ท่านส่งดังกล่าว ให้ถือว่าท่านได้ให้บริษัทฯ บริษัทฯ มีสิทธิ
      และได้รับอนุญาตตลอดไป โดยมิอาจเพิกถอนได้ โดยไม่คิดค่าสิทธิ
      สามารถอนุญาตช่วงและโอนสิทธิได้ ในการใช้ ทำสำเนา เปลี่ยนแปลง ลบทั้งหมด
      ดัดแปลง เผยแพร่ ทำคำแปล สร้างสรรค์คผลงานต่อยอดจาก และ/หรือขาย
      และ/หรือแจกจ่ายข้อมูลดังกล่าว และ/หรือนำข้อมูลนั้นไปใช้ในรูปแบบ สื่อ
      หรือเทคโนโลยีใดๆ ได้ทั่วโลกโดยไม่ต้องจ่ายค่าตอบแทนให้แก่ท่าน
      อีกทั้งท่านยังอนุญาตให้ผู้ใช้เว็บไซต์ของบริษัทฯ
      ได้รับอนุญาตโดยไม่จำกัดสิทธิแต่เพียงผู้เดียวในการเข้าถึงข้อมูลของท่านผ่านเว็บไซต์เหล่านี้
      และใช้ ทำซ้ำ แจกจ่าย จัดทำผลงานต่อยอด แสดง
      หรือนำข้อมูลนั้นไปใช้ตามที่ได้รับอนุญาตตามวัตถุประสงค์ของเว็บไซต์ดังกล่าว
    </p>
    <p>
      ข้อมูลทั้งหมดที่ท่านได้ให้ไว้อาจถูกนำไปใช้โดยดุลพินิจของบริษัทฯ
      แต่เพียงผู้เดียว บริษัทฯ สงวนสิทธิที่จะเปลี่ยนแปลง ตัดทอน หรือลบข้อมูลใดๆ
      บนเว็บไซต์ของบริษัทฯ
      ได้โดยดุลพินิจของตนแต่เพียงผู้เดียวเมื่อเห็นว่าข้อมูลนั้นฝ่าฝืนแนวทางการการนำเสนอข้อมูล
      หรือเงื่อนไขอื่นใดที่ระบุไว้ในนโยบายฉบับนี้ บริษัทฯ ไม่รับรองว่า
      ท่านจะสามารถขอให้บริษัทฯ แก้ไขหรือลบข้อมูลใดๆ ที่ท่านได้ให้ไว้เสมอไป
      การให้คะแนนผลิตภัณฑ์และการแสดงความคิดเห็นเป็นลายลักษณ์อักษรจะถูกนำไปโพสต์เป็นการทั่วไปภายในสองถึงสี่วันทำการ
      อย่างไรก็ตาม บริษัทฯ สงวนสิทธิที่จะนำโพสต์ดังกล่าวออก
      หรือไม่นำโพสต์นั้นลงบนเว็บไซต์ไม่ว่าด้วยเหตุใดก็ได้
    </p>
    <p>
      บริษัทฯ จะเก็บรักษาข้อมูลส่วนบุคคลเป็นระยะเวลาไม่น้อยกว่า 3 (สาม) ปี
      หรือจนกว่าจะเสร็จสิ้นกิจกรรมทางการตลาด หรือเป็นไปตามนโยบายของบริษัทฯ
      และข้อกำหนดตามกฎหมายท้องถิ่น โดยให้ใช้ระยะเวลาที่นานกว่าเป็นเกณฑ์
    </p>
    <h5 className="text-xl font-medium">ผู้เยาว์</h5>
    <p>
      หากคุณมีอายุต่ำกว่า 20 ปีหรือมีข้อจำกัดความสามารถตามกฎหมาย
      เราอาจเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ
      เราอาจจำเป็นต้องให้พ่อแม่หรือผู้ปกครองของคุณให้ความยินยอมหรือที่กฎหมายอนุญาตให้ทำได้
      หากเราทราบว่ามีการเก็บรวบรวมข้อมูลส่วนบุคคลจากผู้เยาว์โดยไม่ได้รับความยินยอมจากพ่อแม่หรือผู้ปกครอง
      เราจะดำเนินการลบข้อมูลนั้นออกจากเซิร์ฟเวอร์ของเรา
    </p>
    <h5 className="text-xl font-medium">วิธีการเก็บรักษาข้อมูลส่วนบุคคล</h5>
    <p>
      บริษัทฯ จะเก็บรักษาข้อมูลส่วนบุคคลของท่านในรูปแบบเอกสาร
      และรูปแบบอิเล็กทรอนิกส์ บริษัทฯ เก็บรักษาข้อมูลส่วนบุคคลของท่าน
      ดังต่อไปนี้
    </p>
    <ul className="list-disc list-inside">
      <li>ระบบเซิร์ฟเวอร์ของบริษัทฯ</li>
      <li>ผู้ให้บริการเซิร์ฟเวอร์ในต่างประเทศ</li>
    </ul>
    <h5 className="text-xl font-medium">การประมวลผลข้อมูลส่วนบุคคล</h5>
    <p>
      บริษัทฯ จะเก็บรวบรวม ใช้
      หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้
    </p>
    <ul className="list-disc list-inside">
      <li>เพื่อปรับปรุงสินค้า บริการ หรือประสบการณ์การใช้งาน</li>
      <li>เพื่อการบริหารจัดการภายในบริษัทฯ</li>
      <li>เพื่อการตลาดและการส่งเสริมการขาย</li>
      <li>เพื่อการบริการหลังการขาย</li>
      <li>เพื่อรวบรวมข้อเสนอแนะ</li>
      <li>เพื่อปฏิบัติตามข้อตกลงและเงื่อนไข (Terms and Conditions)</li>
      <li>เพื่อนำไปทำกิจกรรมต่างๆ ภายในข้อกำหนดและเงื่อนไขของบริษัทฯ</li>
      <li>เพื่อปฏิบัติตามกฎหมายและกฎระเบียบของหน่วยงานราชการ</li>
    </ul>
    <h5 className="text-xl font-medium">การเปิดเผยข้อมูลส่วนบุคคล</h5>
    <p>
      บริษัทฯ
      อาจเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่ผู้อื่นภายใต้ความยินยอมของท่านหรือที่กฎหมายอนุญาตให้เปิดเผยได้
      ดังต่อไปนี้
    </p>
    <ul className="list-disc list-inside">
      <li>การบริหารจัดการภายในองค์กร</li>
      <li>บริษัทฯ ภายนอก เพื่อวิเคราะห์ข้อมูล</li>
    </ul>
    <p>
      บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลของท่านภายในบริษัทฯ
      เท่าที่จำเป็นเพื่อปรับปรุง และพัฒนาสินค้า หรือบริการของบริษัทฯ บริษัทฯ
      อาจรวบรวมข้อมูลภายในสำหรับสินค้า หรือบริการต่างๆ ภายใต้นโยบายนี้
      เพื่อประโยชน์ของท่าน และผู้อื่นมากขึ้น
    </p>
    <h5 className="text-xl font-medium">ระยะเวลาจัดเก็บข้อมูลส่วนบุคคล</h5>
    <p>
      บริษัทฯ
      จะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ตามระยะเวลาที่จำเป็นในระหว่างที่ท่านเป็นลูกค้าหรือมีความสัมพันธ์อยู่กับบริษัทฯ
      หรือตลอดระยะเวลาที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้องกับนโยบายฉบับนี้
      ซึ่งอาจจำเป็นต้องเก็บรักษาไว้ต่อไปภายหลังจากนั้น หากมีกฎหมายกำหนดไว้
      บริษัทฯ จะลบ ทำลาย หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนของท่านได้
      เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าว
    </p>
    <h5 className="text-xl font-medium">สิทธิของเจ้าของข้อมูลส่วนบุคคล</h5>
    <p>
      ภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล ท่านมีสิทธิในการดำเนินการดังต่อไปนี้
    </p>
    <ul className="list-disc list-inside">
      <li>
        สิทธิขอถอนความยินยอม (right to withdraw consent) หากท่านได้ให้ความยินยอม
        เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
        ไม่ว่าจะเป็นความยินยอมที่ท่านให้ไว้ก่อนวันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น
        ท่านมีสิทธิที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดเวลา
      </li>
      <li>
        สิทธิขอเข้าถึงข้อมูล (right to access)
        ท่านมีสิทธิขอเข้าถึงข้อมูลส่วนบุคคลของท่านที่อยู่ในความรับผิดชอบของบริษัทฯ
        และขอให้บริษัทฯ ทำสำเนาข้อมูลดังกล่าวให้แก่ท่าน
        รวมถึงขอให้เราเปิดเผยว่าบริษัทฯ ได้ข้อมูลส่วนบุคคลของท่านมาได้อย่างไร
      </li>
      <li>
        สิทธิขอถ่ายโอนข้อมูล (right to data portability)
        ท่านมีสิทธิขอรับข้อมูลส่วนบุคคลของท่านในกรณีที่บริษัทฯ
        ได้จัดทำข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบให้สามารถอ่านหรือใช้งานได้ด้วยเครื่องมือ
        หรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติ
        และสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ
        รวมทั้งมีสิทธิขอให้บริษัทฯ ส่ง
        หรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น
        เมื่อสามารถทำได้ด้วยวิธีการอัตโนมัติ
        และมีสิทธิขอรับข้อมูลส่วนบุคคลที่บริษัทฯ
        ส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง
        เว้นแต่ไม่สามารถดำเนินการได้เพราะเหตุทางเทคนิค
      </li>
      <li>
        สิทธิขอคัดค้าน (right to object) ท่านมีสิทธิขอคัดค้านการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน ในเวลาใดก็ได้ หากการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของท่านที่ทำขึ้นเพื่อการดำเนินงานที่จำเป็นภายใต้ประโยชน์โดยชอบด้วยกฎหมายของบริษัทฯ
        หรือของบุคคล หรือนิติบุคคลอื่น
        โดยไม่เกินขอบเขตที่คุณสามารถคาดหมายได้อย่างสมเหตุสมผลหรือเพื่อดำเนินการตามภารกิจเพื่อสาธารณประโยชน์
      </li>
      <li>
        สิทธิขอให้ลบ หรือทำลายข้อมูล (right to erasure/destruction)
        ท่านมีสิทธิขอลบ หรือทำลายข้อมูลส่วนบุคคลของท่าน
        หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวท่านได้
        หากท่านเชื่อว่าข้อมูลส่วนบุคคลของท่านถูกเก็บรวบรวม ใช้
        หรือเปิดเผยโดยไม่ชอบด้วยกฎหมายที่เกี่ยวข้องหรือเห็นว่าบริษัทฯ
        หมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้
        หรือเมื่อท่านได้ใช้สิทธิขอถอนความยินยอม
        หรือใช้สิทธิขอคัดค้านตามที่แจ้งไว้ข้างต้นแล้ว
      </li>
      <li>
        สิทธิขอให้ระงับการใช้ข้อมูล (right to restriction of processing)
        ท่านมีสิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลชั่วคราวในกรณีที่บริษัทฯ
        อยู่ระหว่างตรวจสอบตามคำร้องขอใช้สิทธิขอแก้ไขข้อมูลส่วนบุคคล
        หรือขอคัดค้านของท่าน หรือกรณีอื่นใดที่บริษัทฯ หมดความจำเป็นและต้องลบ
        หรือทำลายข้อมูลส่วนบุคคลของท่านตามกฎหมายที่เกี่ยวข้องแต่ท่านขอให้บริษัทฯ
        ระงับการใช้แทน
      </li>
      <li>
        สิทธิขอให้แก้ไขข้อมูล (right to rectification)
        ท่านมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลของท่านให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์
        และไม่ก่อให้เกิดความเข้าใจผิด
      </li>
      <li>
        สิทธิร้องเรียน (right to lodge a complaint)
        ท่านมีสิทธิร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง
        หากท่านเชื่อว่าการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
        เป็นการกระทำในลักษณะที่ฝ่าฝืน
        หรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้องท่านสามารถใช้สิทธิของท่านในฐานะเจ้าของข้อมูลส่วนบุคคลข้างต้นได้
        โดยติดต่อมาที่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของบริษัทฯ
        ตามรายละเอียดท้ายนโยบายนี้ บริษัทฯ จะแจ้งผลการดำเนินการภายในระยะเวลา 30
        วัน นับแต่วันที่บริษัทฯ ได้รับคำขอใช้สิทธิจากท่าน
        ตามแบบฟอร์มหรือวิธีการที่บริษัทฯ กำหนด ทั้งนี้ หากบริษัทฯ
        ปฏิเสธคำขอเราจะแจ้งเหตุผลของการปฏิเสธให้ท่านทราบผ่านช่องทางต่าง ๆ เช่น
        ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น
      </li>
    </ul>
    <h5 className="text-xl font-medium">
      การเข้าถึง การแก้ไข การปรับปรุง หรือการจำกัดการประมวลผลข้อมูลส่วนบุคคล
      และสิทธิอื่นที่เกี่ยวข้องของเจ้าของข้อมูลส่วนบุคคล
    </h5>
    <p>
      หากข้อมูลส่วนบุคคลของท่านมีการเปลี่ยนแปลง (เช่น ที่อยู่ของท่าน)
      หรือหากท่านไม่ประสงค์ให้บริษัทฯ เก็บข้อมูลส่วนบุคคลของท่านไว้ใน
      สารบบอีกต่อไป บริษัทฯ จะพยายามดำเนินการให้สามารถแก้ไข ปรับปรุง
      หรือลบข้อมูลของท่านได้ บริษัทฯ
      อาจเรียกเก็บค่าอำนวยความสะดวกที่เกิดแก่บริษัทฯ
      ในการส่งข้อมูลส่วนบุคคลให้แก่ท่าน หากท่านเห็นว่า บริษัทฯ
      มีข้อมูลเกี่ยวกับท่านที่ไม่ถูกต้องหรือไม่ครบถ้วน ท่านอาจแจ้งให้บริษัทฯ
      แก้ไขข้อมูลดังกล่าวต่อไป
    </p>
    <p>
      โดยทั่วไป บริษัทฯ
      ให้ความร่วมมือและเคารพสิทธิที่เกี่ยวข้องกับข้อมูลส่วนบุคคลทุกประการตามที่กำหนดไว้ในพระราชบัญญัติฯ
      อาทิเช่น สิทธิในการเข้าถึง แก้ไข ระงับ หรือจำกัดการประมวลผลข้อมูลส่วนบุคคล
      การถอนความยินยอม การโอนข้อมูลส่วนบุคคล
      และการร้องเรียนกับหน่วยงานที่มีอำนาจ นอกจากนี้
      ท่านสามารถยกเลิกการรับการติดต่อสื่อสารทางการตลาดที่บริษัทฯ
      ส่งให้แก่ท่านเกี่ยวกับผลิตภัณฑ์และบริการของบริษัทฯ ได้ อย่างไรก็ตาม
      โปรดทราบว่า ในการใช้สิทธิของท่านในฐานะเจ้าของข้อมูลส่วนบุคคล
      การดำเนินการดังกล่าวอาจส่งผลกระทบต่อบริการที่ท่านเลือกรับบริการจากบริษัท
      เวิลด์ แฟร์ จำกัดได้ หรือส่งผลกระทบต่อข้อผูกพันตามสัญญา
      ในกรณีที่การให้ข้อมูลส่วนบุคคลของท่านเป็นเงื่อนไขในการปฏิบัติตามข้อผูกพันตามกฎหมาย
      หรือเพื่อปฏิบัติตามสัญญา หรือรับบริการต่างๆ
    </p>
    <h5 className="text-xl font-medium">การโฆษณาและการตลาด</h5>
    <p>
      เพื่อประโยชน์ในการได้รับสินค้าหรือบริการของบริษัทฯ บริษัทฯ
      ใช้ข้อมูลของคุณเพื่อวิเคราะห์ และปรับปรุงสินค้าหรือบริการ และทำการตลาดผ่าน
      Google, Facebook, และอื่น ๆ บริษัทฯ
      ใช้ข้อมูลดังกล่าวเพื่อให้สินค้าหรือบริการเหมาะสมกับคุณ
    </p>
    <h5 className="text-xl font-medium">เทคโนโลยีติดตามตัวบุคคล (Cookies)</h5>
    <p>
      เพื่อเพิ่มประสบการณ์การใช้งานของคุณให้สมบูรณ์ และมีประสิทธิภาพมากขึ้น
      บริษัทฯ ใช้คุกกี้ (Cookies) หรือเทคโนโลยีที่คล้ายคลึงกัน
      เพื่อพัฒนาการเข้าถึงสินค้าหรือบริการ โฆษณาที่เหมาะสม
      และติดตามการใช้งานของคุณ บริษัทฯ
      ใช้คุกกี้เพื่อระบุและติดตามผู้ใช้งานเว็บไซต์ และการเข้าถึงเว็บไซต์ของเรา
      หากคุณไม่ต้องการให้มีคุกกี้ไว้ในคอมพิวเตอร์ของคุณ
      คุณสามารถตั้งค่าบราวเซอร์เพื่อปฏิเสธคุกกี้ก่อนที่จะใช้เว็บไซต์ของบริษัทฯ
      ได้
    </p>
    <h5 className="text-xl font-medium">
      การรักษาความมั่งคงปลอดภัยของข้อมูลส่วนบุคคล
    </h5>
    <p>
      บริษัทฯ จะรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของคุณไว้ตามหลักการ
      การรักษาความลับ (confidentiality) ความถูกต้องครบถ้วน (integrity)
      และสภาพพร้อมใช้งาน (availability) ทั้งนี้ เพื่อป้องกันการสูญหาย เข้าถึง
      ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผย นอกจากนี้บริษัทฯ
      จะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล
      ซึ่งครอบคลุมถึงมาตรการป้องกันด้านการบริหารจัดการ (administrative
      safeguard) มาตรการป้องกันด้านเทคนิค (technical safeguard)
      และมาตรการป้องกันทางกายภาพ (physical safeguard)
      ในเรื่องการเข้าถึงหรือควบคุมการใช้งานข้อมูลส่วนบุคคล (access control)
    </p>
    <h5 className="text-xl font-medium">การแจ้งเหตุละเมิดข้อมูลส่วนบุคคล</h5>
    <p>
      ในกรณีที่มีเหตุละเมิดข้อมูลส่วนบุคคลของคุณเกิดขึ้น บริษัทฯ
      จะแจ้งให้สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลทราบโดยไม่ชักช้าภายใน 72
      ชั่วโมง นับแต่ทราบเหตุเท่าที่สามารถกระทำได้
      ในกรณีที่การละเมิดมีความเสี่ยงสูงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของคุณ
      บริษัทฯ
      จะแจ้งการละเมิดให้คุณทราบพร้อมกับแนวทางการเยียวยาโดยไม่ชักช้าผ่านช่องทางต่างๆ
      เช่น เว็บไซต์ ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น
    </p>
    <h5 className="text-xl font-medium">
      การแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัว
    </h5>
    <p>
      บริษัทฯ อาจแก้ไขเปลี่ยนแปลงนโยบายนี้เป็นครั้งคราว โดยคุณสามารถทราบข้อกำหนด
      และเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์ของเรา
      นโยบายนี้แก้ไขล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 1 กันยายน 2564
    </p>
    <h5 className="text-xl font-medium">
      นโยบายความเป็นส่วนตัวของเว็บไซต์อื่น
    </h5>
    <p>
      นโยบายความเป็นส่วนตัวฉบับนี้ใช้สำหรับการเสนอสินค้า บริการ
      และการใช้งานบนเว็บไซต์สำหรับลูกค้าของบริษัทฯ เท่านั้น
      หากคุณเข้าชมเว็บไซต์อื่นแม้จะผ่านช่องทางเว็บไซต์ของเรา
      การคุ้มครองข้อมูลส่วนบุคคลต่าง ๆ
      จะเป็นไปตามนโยบายความเป็นส่วนตัวของเว็บไซต์นั้น ซึ่งบริษัทฯ
      ไม่มีส่วนเกี่ยวข้องด้วย
    </p>
    <h4>เงื่อนไขการเข้ารับบริการ</h4>
    <p>
      1. บริษัทฯ เวิลด์ แฟร์ จำกัด จัดทำเว็บไซต์นี้ขึ้นเพื่อให้ข้อมูลข่าวสาร
      เกี่ยวกับงานสดงสินค้า และการลงทะเบียนรับของสมนาคุณของบริษัท เวิลด์ แฟร์
      จำกัด
    </p>
    <p>
      2. เว็บไซต์และเนื้อหาภายในเว็บไซต์นี้ถูกจัดทำขึ้นตามระเบียบข้อบังคับ
      และกฎหมายของประเทศไทยเพื่อให้ผู้ที่อาศัยอยู่ในประเทศไทยได้ใช้ประโยชน์เท่านั้น
    </p>
    <p>
      3. เว็บไซต์นี้เสนอทางเลือกข้อมูลเกี่ยวกับงานแสดงสินค้าของบริษัท เวิลด์
      แฟร์ จำกัด และบทความน่ารู้เกี่ยวกับงานแสดงสินค้า ท่านเลือกที่จะดูข้อมูลนี้
      ถือว่าท่านยอมรับว่าบริษัท เวิลด์ แฟร์ จำกัด
      นำเสนอข้อมูลดังกล่าวตามความต้องการส่วนบุคคลของท่านโดยมีจุดประสงค์เพื่อให้ข้อมูลและความรู้เท่านั้น
    </p>
    <p>
      4. เมื่อเสร็จสิ้นขั้นตอนการลงทะเบียนเข้าสู่งานแสดงสินค้า
      สำหรับท่านที่เป็นผู้เข้าชมงาน เมื่อท่านกรอกข้อมูลให้ถูกต้องและครบถ้วน
      ท่านจะได้รับของสมนาคุณเป็นกระเป๋าผ้าสปันซ์บอน จำนวน 1 ใบ/ท่าน
      ตลอดการจัดงานแสดงสินค้า
    </p>
    <h5 className="text-xl font-medium">เงื่อนไขการรับของสมนาคุณ มีดังนี้</h5>
    <ul className="list-disc list-inside">
      {/*
                    <li>สำหรับผู้เข้าชมงานแสดงสินค้า ที่ลงทะเบียนล่วงหน้า ตั้งแต่วันที่..............................
จะได้รับกระเป๋าผ้าสปันซ์บอนลาย........... พร้อมของของในกระเป๋า จำนวน 1 ใบ/ท่าน ตลอดการจัดงาน</li>
                */}
      <li>
        สำหรับผู้เข้าชมงานแสดงสินค้า ที่กรอกข้อมูลเพื่อลงทะเบียน
        จะได้รับของชำร่วย เพียง 1 ชิ้น ตลอดการจัดงาน
      </li>
    </ul>
    <p>
      หากท่านกรอกข้อมูลไม่ครบถ้วน ท่านจะไม่สามารถรับของสมนาคุณกับทางบริษัท
      เวิลด์ แฟร์ จำกัด ได้ และผู้ได้รับของสมนาคุณแล้ว ไม่สามารถโอนสิทธิ์
      แลกเปลี่ยน หรือทอนของรางวัลเป็นเงินสดได้
    </p>
    <p>
      ผู้ที่ลงทะเบียนทุกท่านตกลงที่จะได้รับข่าวสารและโปรโมชั่นจากบริษัท เวิลด์
      แฟร์ จำกัด ผ่านทางอีเมล, ข้อความผ่านทางโทรศัพท์มือถือ (SMS), LINE Official
      Account และโฆษณาทางการตลาด จาก เฟซบุ๊ก (Facebook) และ กูเกิ้ล (Google)
    </p>
    <p>
      5. การลงทะเบียนกับบริษัท เวิลด์ แฟร์ จำกัด
      ท่านต้องกรอกข้อมูลให้ถูกต้องและครบถ้วนทั้ง ชื่อ ที่อยู่ เบอร์โทรศัพท์
      อีเมล ฯลฯ โดยท่านรับรอง และรับประกันว่าข้อมูลที่ท่านให้จะเป็นปัจจุบัน
      เป็นความจริง ถูกต้องแม่นยำ พิสูจน์ได้ และสมบูรณ์
      หากท่านให้ข้อมูลที่ไม่ถูกต้องหรือไม่สมบูรณ์
      อาจส่งผลต่อการประมวลผลของเว็บไซต์ โดยทางบริษัท เวิลด์ แฟร์ จำกัด
      จะทำการรวบข้อมูลเหล่านี้เพื่อประโยชน์ในการติดต่อ
      และแจ้งข่าวสารรวมทั้งโปรโมชั่นของทางบริษัท เวิลด์ แฟร์ จำกัด
      จนกว่าท่านจะบอกเลิกการเป็นสมาชิกเว็บไซต์ และไม่ประสงค์จะให้บริษัท เวิลด์
      แฟร์ จำกัด เก็บข้อมูลดังกล่าวของท่านอีกต่อไป
    </p>
    <p>
      6. การใช้เว็บไซต์นี้ถือเป็นความเสี่ยงของท่านเอง บริษัท เวิลด์ แฟร์ จำกัด
      จะไม่รับผิดชอบต่อความเสียหายใดๆ ทั้งสิ้น ไม่ว่าจะเป็นความเสียหายโดยตรง
      ความเสียหายโดยอ้อม ความเสียหายโดยบังเอิญ หรือความเสียหายที่เป็นผลสืบเนื่อง
      ที่เกิดจากการเข้าถึงหรือการใช้บริการเว็บไซต์
      ซึ่งรวมถึงความเสียหายที่เกิดขึ้นกับ
      หรือจากการติดไวรัสในอุปกรณ์คอมพิวเตอร์ของท่าน
      นอกเหนือจากสิ่งที่กล่าวมาแล้วข้างต้น ข้อมูลที่แสดงไว้ในเว็บไซต์นี้
      ได้ถูกจัดเตรียมขึ้นและนำเสนอต่อท่านบนพื้นฐาน “ตามที่ข้อมูลนั้นมีอยู่” และ
      “ตามที่ข้อมูลนั้นถูกหามาได้” บริษัทฯ ไม่รับประกันว่า
      ข้อมูลและส่วนประกอบดังกล่าวมีความถูกต้อง
      สมบูรณ์เพียงพอเหมาะสมกับวัตถุประสงค์ใดโดยเฉพาะ
    </p>
    <p>
      7. บริษัท เวิลด์ แฟร์ จำกัด
      เป็นเจ้าของลิขสิทธิ์เนื้อหาทั้งหมดในเว็บไซต์นี้
      ผู้ใช้สามารถดาวน์โหลดข้อมูลต่างๆ
      เพื่อการใช้งานส่วนบุคคลที่ไม่เกี่ยวข้องกับการใช้งานเชิงพาณิชย์
      โดยจะต้องคงประกาศเกี่ยวกับลิขสิทธิ์และกรรมสิทธิ์ทั้งหมดไว้โดยสมบูรณ์
      ห้ามมิให้ทำการลอกเลียนแบบ เผยแพร่ซ้ำ ลงประกาศซ้ำ ตีพิมพ์ ปรับเปลี่ยน
      หรือดัดแปลงแก้ไขส่วนหนึ่งส่วนใดในเว็บไซต์นี้เพื่อเผยแพร่ต่อสาธารณชนหรือเพื่อวัตถุประสงค์ในเชิงพาณิชย์โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรล่วงหน้าจากบริษัท
      เวิลด์ แฟร์ จำกัด
    </p>
    <p>
      8. เครื่องหมายการค้า โลโก้ ตัวอักษรและสัญลักษณ์ในการให้บริการ (
      รวมเรียกว่า 'เครื่องหมายการค้า' ) ที่ปรากฏบนเว็บไซต์
      เป็นเครื่องหมายการค้าที่จดทะเบียนและไม่จดทะเบียนของบริษัท เวิลด์ แฟร์
      จำกัด
      ข้อมูลทั้งหมดในเว็บไซต์นี้ไม่ได้บ่งชี้ว่าเป็นการให้สิทธิ์หรืออนุญาตให้ใช้สิทธิ์
      ไม่ว่าโดยนัย นิ่งเฉย
      หรือในลักษณะใดก็ตามในเครื่องหมายการค้าและใช้เครื่องหมายการค้าโดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรจากบริษัท
      เวิลด์ แฟร์ จำกัด
      ห้ามมิให้นำเครื่องหมายการค้าและเนื้อหาในเว็บไซต์นี้ไปใช้งานนอกเหนือจากข้อตกลงและเงื่อนไขที่ระบุไว้โดยเด็ดขาด
      โปรดทราบว่าบริษัท เวิลด์ แฟร์ จำกัด
      จะดำเนินการทางกฎหมายซึ่งรวมทั้งการดำเนินคดีทางอาญาอย่างถึงที่สุดกับผู้ละเมิดสิทธิ์ในทรัพย์สินทางปัญญาและเนื้อหาในเว็บไซต์
    </p>
    <p>
      9. รูปภาพบุคคลหรือสถานที่ที่ปรากฏอยู่บนเว็บไซต์นี้เป็นทรัพย์สินของบริษัท
      เวิลด์ แฟร์ จำกัด หรือบริษัท เวิลด์ แฟร์ จำกัด
      นำมาใช้งานโดยได้รับอนุญาตแล้ว ห้ามนำรูปภาพเหล่านี้ไปใช้งาน
      หากมิได้มีการอนุญาตไว้โดยเฉพาะเจาะจงในข้อตกลงและเงื่อนไขเหล่านี้
      หรือการอนุญาตเป็นกรณีพิเศษที่ระบุไว้ในที่อื่นของเว็บไซต์นี้
      การนำรูปภาพไปใช้โดยไม่ได้รับอนุญาตอาจละเมิดลิขสิทธิ์ เครื่องหมายการค้า
      นโยบายความเป็นส่วนตัวและ/หรือ กฎหมายในการเผยแพร่
      ตลอดจนข้อบังคับและพระราชบัญญัติที่เกี่ยวข้อง
    </p>
    <p>
      10. หากท่านพบเห็นการดำเนินการใดๆ
      อันมิชอบด้วยกฎหมายซึ่งเกี่ยวข้องกับเนื้อหาที่ผู้ใช้ท่านอื่นลงประกาศหรืออยู่ในเว็บไซต์
      และท่านต้องการแจ้งเรื่องร้องเรียน โปรดติดต่อมาที่
      เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล บริษัท เวิลด์ แฟร์ จำกัด
    </p>
    <p>
      11. เว็บไซต์นี้อาจมีลิงค์เชื่อมโยงไปยังเว็บไซต์อื่นๆ บริษัท เวิลด์ แฟร์
      จำกัด จะไม่รับผิดชอบต่อเนื้อหาในเว็บไซต์อื่นๆ
      หรือบทวิจารณ์ต่อเนื่องในเว็บไซต์อื่นๆ
      การรวบรวมลิงค์ที่เชื่อมไปยังเว็บไซต์เหล่านั้นไม่ได้ถือว่าเป็นการรับรองความน่าเชื่อถือของเว็บไซต์ดังกล่าวจากบริษัท
      เวิลด์ แฟร์ จำกัด การเชื่อมโยงไปยังเว็บไซต์อื่นๆ
      ถือเป็นความเสี่ยงของท่านเอง
      ห้ามมิให้ทำการเชื่อมโยงมายังเว็บไซต์นี้ไม่ว่าด้วยวิธีการใดๆ ก็ตาม
      โดยมิได้รับอนุญาตเป็นลายลักษณ์อักษรจากบริษัท เวิลด์ แฟร์ จำกัด
    </p>
    <p>
      12. บริษัท เวิลด์ แฟร์ จำกัด ไม่รับรองหรือรับประกันว่าเว็บไซต์นี้
      หรือเว็บไซต์ใดๆ ก็ตามที่เชื่อมโยงจากเว็บไซต์ของบริษัท เวิลด์ แฟร์ จำกัด
      จะใช้งานได้ตามช่วงเวลาที่ต้องการ
      หรือการเข้าใช้งานเว็บไซต์ดังกล่าวจะไม่มีการขัดข้องหรือปราศจากข้อผิดพลาด
    </p>
    <p>
      13. บริษัท เวิลด์ แฟร์ จำกัด ขอสงวนสิทธิ์ในการเปลี่ยนแปลง
      หรือลบข้อมูลออกจากเว็บไซต์ได้ตลอดเวลา
    </p>
    <p>
      14. บริษัท เวิลด์ แฟร์ จำกัด
      สามารถแก้ไขข้อกำหนดและเงื่อนไขเกี่ยวกับการใช้เว็บไซต์นี้ โดยการอัปเดต
      และโพสต์เวอร์ชันใหม่ลงในเว็บไซต์
      เพื่อการแก้ไขปรับปรุงข้อความเหล่านี้ได้ตลอดเวลา
      การแก้ไขข้อกำหนดและเงื่อนไขดังกล่าวมีผลผูกพันกับท่าน
      ดังนั้นท่านควรเยี่ยมชมหน้าข้อกำหนดและเงื่อนไขดังกล่าวเป็นระยะๆ
      เพื่อตรวจสอบข้อกำหนดและเงื่อนไขที่ใช้อยู่ในขณะนั้น
      อย่างไรก็ตามข้อกำหนดและเงื่อนไขที่มีการแก้ไขจะมีผลกับการใช้เว็บไซต์นี้นับจากวันที่โพสต์
      และหากท่านไม่เห็นด้วยกับข้อกำหนดและเงื่อนไขที่มีการแก้ไขนี้
      ท่านมีสิทธิ์ที่จะหยุดใช้เว็บไซต์นี้ทันทีในขณะนั้น
      และ/หรือบอกเลิกการเป็นสมาชิกเว็บไซต์
    </p>
    <p>
      16. บริษัท เวิลด์ แฟร์ จำกัด ขอสงวนสิทธิ์ในการลบรูปภาพ วิดิโอคลิป
      และประโยคที่ไม่เหมาะสมในหัวข้อสนทนา และการแสดงความคิดเห็นต่างๆ
      ภายในเว็บไซต์โดยมิต้องแจ้งให้ทราบล่วงหน้า
    </p>
    <p>
      17. ข้อตกลงนี้จะอยู่ภายใต้และตีความตามกฎหมายของประเทศไทย
      ทั้งสองฝ่ายตกลงที่จะเสนอข้อพิพาทต่อศาลยุติธรรมแห่งราชอาณาจักรไทย
    </p>
    <h5 className="text-xl font-medium">รายละเอียดการติดต่อ</h5>
    <p>
      หากท่านประสงค์ที่จะเข้าถึงข้อมูลส่วนบุคคลของท่าน
      หรือหากท่านมีข้อสงสัยเกี่ยวกับนโยบายความเป็นส่วนตัวของบริษัทฯ
      หรือมีข้อร้องเรียนใดๆ
      เกี่ยวกับการถือปฏิบัติต่อความเป็นส่วนตัวของท่านโดยบริษัทฯ โปรดติดต่อ
    </p>
    <p>
      <strong>เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</strong>
    </p>
    <p>
      บริษัท เวิลด์ แฟร์ จำกัด
      <br />
      1,3,5,7 ซอยลาดพร้าว 128/1 ถนนลาดพร้าว แขวงคลองจั่น เขตบางกะปิ
      กรุงเทพมหานคร 10240
      <br />
      เว็บไซต์ www.worldfair.co.th
      <br />
      หมายเลขโทรศัพท์ 02-731-1331
    </p>
  </div>
);

export default Condition;
