import React from "react";

export default function ContractPrintCondition() {
  return (
    <div id="contractprintcondition" className="my-3 text-[10px]">
      <p className={"text-center"}>
        <u>เงื่อนไขของสัญญาบริการพื้นที่แสดงสินค้า</u>
      </p>
      <div id="context">
        <p>
          <u>สินค้าที่นำมาแสดงภายในงาน</u>
          <br />
          1.สินค้าหรือบริการที่นำมาจัดแสดงภายในงานต้องไม่ผิดกฎหมาย
          ไม่มีการละเมิดลิขสิทธิ์
          หรือต้องเป็นสินค้าหรือบริการที่ผ่านการรับรองหรือขออนุญาตตามกฎหมายแล้ว
          (กรณีที่มีกฏหมายกำหนดให้รับรองหรือต้องขออนุญาต)
          กรณีมีการละเมิดลิขสิทธิ์ถือเป็น
          ความรับผิดชอบของผู้แสดงสินค้าเองทั้งสิ้น
          รวมถึงหน้าที่ในการเสียภาษีตามกฏหมายต่อกรมสรรพากรและอื่นๆด้วย
          <br />
          2.สินค้าหรือบริการที่นำมาจัดแสดงต้องเป็นสินค้าหรือบริการที่ระบุไว้ตามตารางข้างต้นเท่านั้น
          ไม่สามารถนำสินค้าหรือบริการที่ไม่ตรงตามตารางที่ระบุไว้มาแสดงโดยเด็ดขาด
          <br />
          3.ผู้แสดงสินค้า
          จะขายต่อหรือให้ผู้อื่นใช้หรือเช่าพื้นที่บริการตามสัญญานี้ไม่ได้
          ทั้งนี้ผู้แสดงสินค้ามีหน้าที่ในการเปิดและปิดคูหาพื้นที่แสดงสินค้าตามวันและเวลาที่กำหนดด้วย
        </p>
        <br />
        <p>
          <u>พื้นที่แสดงสินค้าและพื้นที่ภายในบริเวณงาน</u>
          <br />
          4.ผู้แสดงสินค้าจะต้องรับผิดชอบต่ออุบัติเหตุและความเสียหายใดๆอันเกิดขึ้นต่อพื้นที่แสดงสินค้า
          พื้นที่ภายในบริเวณงานหรือพื้นที่จัดแสดงสินค้าของผู้แสดงสินค้ารายอื่นๆ
          หากอุบัติเหตุหรือความเสียหายนั้นๆ
          เกิดขึ้นจากผู้แสดงสินค้าหรือผู้รับเหมาก่อสร้างของผู้แสดงสินค้า
          รวมถึงบริวารและลูกจ้างของผู้แสดงสินค้า
          <br />
          5.ผู้แสดงสินค้าจะต้องขอรับบริการติดตั้งแหล่งจ่ายกระแสไฟฟ้ารวมถึงแสงสว่าง
          และปฏิบัติตามคำแนะนำของผู้รับเหมาการติดตั้งไฟฟ้าของศูนย์แสดงสินค้านั้นๆ
          โดยเคร่งครัด หากเกิดความเสียหายใดๆ
          อันเกิดจากการใช้กระแสไฟฟ้าของผู้แสดงสินค้า
          ผู้แสดงสินค้าจะต้องรับผิดชอบเองทั้งสิ้น
          <br />
          6.ไม่ว่ากรณีใดๆ ผู้จัดงานจะต้องรับผิดชอบต่อความเสียหาย
          หรือสูญหายของสินค้า หรือทรัพย์สินที่นำมาแสดงภายในงานทั้งสิ้น
          <br />
          7.ผู้จัดงานสงวนสิทธิในการปรับเปลี่ยนรูปแบบการจัดงาน
          แผนผังบริเวณการจัดงาน ตำแหน่งของพื้นที่แสดงสินค้าของผู้แสดงสินค้า
          ทั้งนี้ให้เป็นดุลยพินิจของผู้จัดงานแต่เพียงฝ่ายเดียว
          โดยมิพักต้องแจ้งให้ผู้แสดงสินค้าทราบล่วงหน้า ทั้งนี้ผู้แสดงสินค้า
          ไม่สามารถยกข้อต่อสู้ หรือเหตุผลอื่นใด
          เพื่อให้ผู้จัดงานเป็นฝ่ายผิดสัญญาใดๆ ทั้งสิ้น
        </p>
        <br />
        <p>
          <u>การแสดงสินค้าหรือการใช้พื้นที่บริการแสดงสินค้า</u>
          <br />
          8.
          ผู้แสดงสินค้าจะต้องปฏิบัติตามข้อกำหนดระเบียบที่ได้กำหนดไว้ในคู่มือแสดงสินค้าอย่างเคร่งครัด
          ทั้งนี้
          <br />
          <ul className="pl-3">
            <li>
              8.1. ห้ามวาง หรือจัดแสดงสินค้าภายนอกพื้นที่แสดงสินค้า
              หรือล้ำพื้นที่ทางเดินส่วนกลาง
            </li>
            <li>
              8.2. ห้ามทำกิจกรรม หรือแจกเอกสาร แนะนำ
              หรือชักชวนผู้ชมงานบริเวณภายนอกพื้นที่แสดงสินค้า
            </li>
            <li>
              8.3. ห้ามใช้เครื่องกระจายเสียง (ยกเว้น กรณีใช้พื้นที่ตั้งแต่ 6
              คูหา สามารถขออนุญาตผู้จัดงานได้ตามเงื่อนไขที่กำหนด) รวมถึงห้ามแขวน
              หรือติดตั้งป้าย บอลลูน ภายในบริเวณงาน
            </li>
          </ul>
          9.กรณีผู้แสดงสินค้าทำผิดระเบียบหรือ ข้อกำหนดก็ดี
          หรือไม่ได้ชำระค่าบริการพื้นที่แสดงสินค้าให้เสร็จสิ้นก็ดี
          ผู้จัดงานมีสิทธิระงับการแสดงสินค้า
          หรือทำการงดจ่ายกระแสไฟฟ้าให้แก่พื้นที่แสดงสินค้าได้
          ทั้งนี้ผู้แสดงสินค้าจะเรียกร้องความเสียหาย
          หรือค่าอื่นใดจากผู้จัดงานไม่ได้ทั้งสิ้น
        </p>
        <br />
        <p>
          <u>การยกเลิกสัญญาบริการพื้นที่แสดงสินค้า</u>
          <br />
          10.ผู้แสดงสินค้าจะยกเลิกสัญญาบริการพื้นที่แสดงสินค้าไม่ได้
          เว้นไว้แต่ได้รับความยินยอมจากผู้จัดงานเป็นลายลักษณ์อักษร
          โดยทั้งนี้อยู่ภายใต้เงื่อนไขดังนี้
          <ul className="pl-3">
            <li>
              10.1.ยกเลิกก่อนการจัดงาน 30 วัน ผู้แสดงสินค้า
              ต้องเสียค่าปรับให้แก่ผู้จัดงาน เป็นเงิน 50% ของค่าบริการพื้นที่
            </li>
            <li>
              10.2.ยกเลิกภายใน 30 วัน ก่อนการจัดงานผู้แสดงสินค้า
              ต้องเสียค่าปรับให้แก่ผู้จัดงาน เป็นเงิน 100% ของค่าบริการพื้นที่
            </li>
          </ul>
          11.กรณีเกิดเหตุสุดวิสัย หรือเหตุใดๆ
          อันส่งผลให้ผู้จัดงานต้องยกเลิกการให้บริการพื้นที่แสดงสินค้า
          ผู้จัดงานจะคืนค่าบริการพื้นที่แสดงสินค้าตามอัตราดังนี้
          <ul className="pl-3">
            <li>
              11.1.กรณีต้องยกเลิกก่อนวันจะแสดงสินค้า 30 วัน คืนเงินเต็มจำนวน
              โดยไม่มีดอกเบี้ย
            </li>
            <li>
              11.2.กรณีต้องยกเลิกภายใน 30 วันก่อนการจัดงาน
              จนถึงวันเข้าตกแต่งพื้นที่ คืนเงิน 15% ของค่าบริการพื้นที่
              โดยไม่มีดอกเบี้ย
            </li>
            <li>
              11.3.กรณีต้องยกเลิกในวันจัดงาน จะไม่คืนเงินในทุกกรณี
              ทั้งนี้ผู้เสียหายสินค้ายังคงต้องผูกพันต่อหนี้ หรือค่าบริการพื้นที่
              ที่มีอยู่กับผู้จัดงานจนเต็มจำนวน
            </li>
          </ul>
          <br />
          12. กรณีผู้แสดงสินค้าไม่ปฏิบัติตามกฎระเบียบ หรือกระทำการใดๆ
          อันเกิดความเสียหาย ความไม่สงบภายในงาน
          ผู้จัดงานสงวนสิทธิยกเลิกสัญญาฉบับนี้ได้ในทันที
          ทั้งนี้ผู้แสดงสินค้ายังคงต้องรับผิดชอบต่อค่าบริการพื้นที่ตามสัญญานี้เต็มจำนวน
          และไม่สามารถยกข้อต่อสู้ หรือข้อเรียกร้องใดๆ ต่อผู้จัดงานทั้งสิ้น
          <br />
          13. เมื่อสิ้นสุดงานแสดงสินค้า ผู้แสดงสินค้าจะต้องขนย้ายสินค้า
          หรือทรัพย์สินของผู้แสดงสินค้ารวมทั้งรื้อถอนคูหาแสดงสินค้าให้เสร็จสิ้นตามกำหนดในสัญญานี้
          หรือกรณีงานแสดงสินค้าต้องสิ้นสุดลงไม่ว่าเหตุใดๆ
          ผู้แสดงสินค้าต้องทำการรื้อถอนขนย้ายสินค้าและทรัพย์สินในทันที
          และต้องทำการให้เสร็จสิ้นภายในวันถัดไป
          กรณีผู้แสดงสินค้าไม่ยอมทำการขนย้าย
          ผู้จัดงานซึ่งมีหน้าที่ในการส่งมอบพื้นที่คืนให้แก่ศูนย์แสดงสินค้า
          สามารถเข้าดำเนินการรื้อถอนขนย้ายได้
          ทั้งนี้ผู้แสดงสินค้าจะต้องรับผิดชอบในค่าใช้จ่ายรวมถึงค่าเสียหายใดๆ
          อันเกิดจากการนี้เองทั้งสิ้น
          <br />
          14. ผู้แสดงสินค้ารวมถึงบริวารของผู้แสดงสินค้า
          ยินยอมให้ผู้จัดงานเปิดเผยข้อมูลส่วนบุคคลของผู้แสดงสินค้า รูปถ่าย
          หรือวิดีโอเพื่อเก็บรวบรวม ใช้ วิเคราะห์ข้อมูล วางแผนการตลาด
          รวมถึงการโฆษณาประชาสัมพันธ์ต่อบุคคลที่เกี่ยวข้อง
          เพื่อให้บรรลุถึงวัตถุประสงค์ในการจัดงานแสดงสินค้า
          <br />
          15.
          ข้อความในสัญญาบริการพื้นที่แสดงสินค้าที่ปรากฏข้างต้นทุกข้อเป็นสาระสำคัญของสัญญา
          หากผู้แสดงสินค้าประพฤติผิดสัญญาข้อหนึ่งข้อใด หรือหลายข้อ
          ผู้จัดงานมีสิทธิบอกเลิกสัญญานี้โดยให้มีผลในทันที
          โดยมิพักต้องบอกกล่าวก่อน
          <br />
          16. บรรดาหนังสือ หรือคำบอกกล่าวใดๆ ที่คู่สัญญามีไปถึง
          หรือส่งมายังคู่สัญญาอีกฝ่ายหนึ่งตามที่อยู่ที่ปรากฏในสัญญานี้แล้วโดยการส่งทางไปรษณีย์ลงทะเบียนตอบรับ
          จดหมายอิเล็กทรอนิกส์หรือส่งโดยพนักงานของคู่สัญญาฝ่ายหนึ่งโดยมีหลักฐานการรับจากคู่สัญญาอีกฝ่ายหนึ่ง
          หรือลูกจ้าง หรือบริวารของคู่สัญญาฝ่ายนั้นแล้ว
          ให้ถือว่าเป็นการส่งหนังสือ
          หรือคำบอกกล่าวโดยชอบแล้วตั้งแต่วันที่ได้ส่งถึง หรือเซ็นรับดังกล่าว
        </p>
        <p>
          สัญญานี้ทําขึ้นเป็นสองฉบับมีข้อความตรงกันคู่สัญญาทั้งสองฝ่ายได้อ่านและเข้าใจข้อความโดยตลอดแล้วเห็นว่าถูกตอ้งตรงตามความประสงค์ทุกประการจึงลงลายมือชื่อไว้เป็นหลักฐานและต่อหน้าพยาน
        </p>
      </div>
    </div>
  );
}
