import RegistPage from "./components/registration/I125/registration";
import PreregistPage from "./components/registration/I125/preregistration";
import PostPage from "./components/registration/I125/postregister";

import RegistPage_dc from "./components/registration/B424/registration";
import PreregistPage_dc from "./components/registration/B424/preregistration";
import PostPage_dc from "./components/registration/B424/postregister";

const AppRegistRoutes = [
  {
    path: "i125/preregistration",
    element: <PreregistPage />,
  },
  {
    path: "i125/preregistration/:cp",
    element: <PreregistPage />,
  },
  {
    path: "i125/registration",
    element: <RegistPage />,
  },
  {
    path: "i125/postregister/:res/:key",
    element: <PostPage />,
  },

  {
    path: "b424/preregistration",
    element: <PreregistPage_dc />,
  },
  {
    path: "b424/preregistration/:cp",
    element: <PreregistPage_dc />,
  },
  {
    path: "b424/registration",
    element: <RegistPage_dc />,
  },
  {
    path: "b424/postregister/:res/:key",
    element: <PostPage_dc />,
  },
];

export default AppRegistRoutes;
