import React, { useState, useContext } from "react";
import { dataContext } from "../contractPrint";

export default function ContractPrintCompany() {
  const { contractC } = useContext(dataContext);

  const [contract, setContract] = contractC;

  const blank = "........................................";
  return (
    <div id="contractprintinfo" className="my-3">
      <table className="w-full">
        <tbody>
          <tr className="border border-black">
            <td className="pl-4">รายละเอียดของงานแสดงสินค้า</td>
            <td className="border-l border-black px-2">
              งานแสดงสินค้า <strong>{" " + contract.exname}</strong>
              <br />
              สถานที่ <strong>{" " + contract.venue}</strong> <br />
              วันที่แสดง <strong>{" " + contract.date + " "}</strong> เวลา{" "}
              <strong>{" " + contract.time}</strong>
            </td>
          </tr>
          <tr className="border border-black">
            <td className="pl-4">รายละเอียดของพื้นที่แสดงสินค้า</td>
            <td className="border-l border-black px-2">
              หมายเลขบูธ{" "}
              {contract.booth !== "" ? (
                <strong>{" " + contract.booth.toUpperCase() + " "}</strong>
              ) : (
                blank
              )}
              พื้นที่โดยประมาณ{" "}
              {contract.space !== "" ? (
                <strong>{" " + contract.space + " "}</strong>
              ) : (
                blank
              )}
              ตารางเมตร
            </td>
          </tr>
          <tr id="product" className="border border-black h-[52px]">
            <td className="pl-4">รายละเอียดของสินค้าที่นำมาแสดง</td>
            <td className="border-l border-black px-2">
              <strong>{" " + contract.product}</strong>
            </td>
          </tr>
          <tr className="border border-black">
            <td className="pl-4">ค่าบริการพื้นที่แสดงสินค้า</td>
            <td className="border-l border-black px-2">
              <div className="flex flex-col justify-center w-fit">
                <div className="text-left">
                  รวมเป็นเงินทั้งสิ้น{" "}
                  {contract.cost !== "" ? (
                    <strong>
                      {" " +
                        Number(contract.cost)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                        " "}
                    </strong>
                  ) : (
                    blank
                  )}
                  บาท (ราคารวมภาษีมูลค่าเพิ่มแล้ว)
                </div>
                <div className="text-center" id="costtext">
                  (จำนวนเงินเป็นตัวอักษร)
                </div>
                <div className="text-center" id="costtext-text">
                  {contract.cost !== "" ? (
                    <strong>{" " + contract.costtext}</strong>
                  ) : (
                    "........................................................................................................."
                  )}
                </div>
              </div>
            </td>
          </tr>
          <tr className="border border-black">
            <td className="pl-4">การชำระเงิน</td>
            <td className="border-l border-black px-2">
              1.ชำระเงินมัดจำ{" "}
              <strong>
                {contract.deposit !== "" ? (
                  <strong>
                    {" " +
                      Number(contract.deposit)
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                      " "}
                  </strong>
                ) : (
                  blank
                )}
              </strong>
              บาท ภายใน{" "}
              <strong>
                {contract.wtndep !== "" ? (
                  <strong>{" " + contract.wtndep + " "}</strong>
                ) : (
                  blank
                )}
              </strong>
              <br />
              2.ชำระเงินส่วนแรก{" "}
              <strong>
                {contract.pay1 !== "" ? (
                  <strong>
                    {" " +
                      Number(contract.pay1)
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                      " "}
                  </strong>
                ) : (
                  blank
                )}
              </strong>
              บาท ภายใน{" "}
              <strong>
                {contract.wtn1 !== "" ? (
                  <strong>{" " + contract.wtn1 + " "}</strong>
                ) : (
                  blank
                )}
              </strong>
              <br />
              3.ชำระเงินส่วนที่เหลือ{" "}
              {contract.pay2 !== "" ? (
                <strong>
                  {" " +
                    Number(contract.pay2)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    " "}
                </strong>
              ) : (
                blank
              )}
              บาท ภายใน{" "}
              {contract.pay2 !== "" ? (
                <strong>{" " + contract.wtn2}</strong>
              ) : (
                blank
              )}
              {contract.cheque !== "" ? (
                <div>
                  โดยสั่งจ่ายเป็นเช็คล่วงหน้า ภายในวันที่ {contract.cheque}
                </div>
              ) : (
                ""
              )}
            </td>
          </tr>
          <tr className="border border-black">
            <td className="pl-4">วันเข้าตกแต่งพื้นที่แสดงสินค้า</td>
            <td className="border-l border-black px-2">
              วันที่{" "}
              <strong>
                {contract.movein1 !== "" ? (
                  <strong>{" " + contract.movein1}</strong>
                ) : (
                  blank
                )}
              </strong>{" "}
              ระหว่างเวลา{" "}
              <strong>
                {contract.mit1 !== "" ? (
                  <strong>{" " + contract.mit1}</strong>
                ) : (
                  blank
                )}
              </strong>
              {contract.movein2 !== "" && (
                <>
                  <br />
                  วันที่ <strong>{" " + contract.movein2 + " "}</strong>{" "}
                  ระหว่างเวลา <strong>{" " + contract.mit2}</strong>
                </>
              )}
            </td>
          </tr>
          <tr className="border border-black">
            <td className="pl-4">วันรื้อถอน คูหาแสดงสินค้า</td>
            <td className="border-l border-black px-2">
              วันที่{" "}
              <strong>
                {contract.moveout1 !== "" ? (
                  <strong>{" " + contract.moveout1 + " "}</strong>
                ) : (
                  blank
                )}
              </strong>{" "}
              ระหว่างเวลา{" "}
              <strong>
                {contract.mot1 !== "" ? (
                  <strong>{" " + contract.mot1 + " "}</strong>
                ) : (
                  blank
                )}
              </strong>
              {contract.moveout2 !== "" && (
                <>
                  <br />
                  วันที่ <strong>{" " + contract.moveout2 + " "}</strong>{" "}
                  ระหว่างเวลา <strong>{" " + contract.mot2}</strong>
                </>
              )}
            </td>
          </tr>
          {/*
          <tr className="border border-black">
            <td className="pl-4">ประเภทของคูหาแสดงสินค้า</td>
            <td className="border-l border-black px-2">
              <strong>
                {
                  [
                    "คูหาแสดงสินค้าพร้อมอุปกรณ์ไฟฟ้ามาตรฐาน",
                    "พื้นที่ว่างเปล่าผู้ จัดงานเตรียมให้เฉพาะอุปกรณ์ไฟฟ้ามาตรฐาน",
                    "พื้นที่ว่างเปล่า ผู้แสดงสินค้าเป็นผู้ดำเนินการเองทั้งสิ้น",
                  ].filter((item, i) => contract.type === i)[0]
                }
              </strong>
            </td>
          </tr>
          */}
        </tbody>
      </table>
    </div>
  );
}
