import React, { useState } from "react";

export default function OurSuccess(props) {
  const [data, setData] = useState([
    {
      file: "success_230623_1.jpg",
      show: "Furniture Maga Show 2023",
      showdate: "14 Jan 23 - 22 Jan 23",
    },
    {
      file: "success_230623_2.jpg",
      show: "Furniture Maga Show 2023",
      showdate: "14 Jan 23 - 22 Jan 23",
    },
    {
      file: "success_230623_3.jpg",
      show: "Furniture Maga Show 2023",
      showdate: "14 Jan 23 - 22 Jan 23",
    },
    {
      file: "success_230623_4.jpg",
      show: "Furniture Maga Show 2023",
      showdate: "14 Jan 23 - 22 Jan 23",
    },
    {
      file: "success_230623_5.jpg",
      show: "Furniture Maga Show 2023",
      showdate: "14 Jan 23 - 22 Jan 23",
    },
    {
      file: "success_230623_6.jpg",
      show: "Furniture Maga Show 2023",
      showdate: "14 Jan 23 - 22 Jan 23",
    },
    {
      file: "success_230623_7.jpg",
      show: "Furniture Maga Show 2023",
      showdate: "14 Jan 23 - 22 Jan 23",
    },
    {
      file: "success_230623_8.jpg",
      show: "Furniture Maga Show 2023",
      showdate: "14 Jan 23 - 22 Jan 23",
    },
    {
      file: "success_230623_9.jpg",
      show: "Home Decor 2023@QSNCC",
      showdate: "22 Fab 23 - 26 Feb 23",
    },
    {
      file: "success_230623_110.jpg",
      show: "Home Decor 2023@QSNCC",
      showdate: "22 Fab 23 - 26 Feb 23",
    },
    {
      file: "success_230623_111.jpg",
      show: "Home Decor 2023@QSNCC",
      showdate: "22 Fab 23 - 26 Feb 23",
    },
    {
      file: "success_230623_112.jpg",
      show: "Home Decor 2023@QSNCC",
      showdate: "22 Fab 23 - 26 Feb 23",
    },
    {
      file: "success_230623_113.jpg",
      show: "Home Decor 2023@QSNCC",
      showdate: "22 Fab 23 - 26 Feb 23",
    },
    {
      file: "success_230623_114.jpg",
      show: "Home Decor 2023@QSNCC",
      showdate: "22 Fab 23 - 26 Feb 23",
    },
    {
      file: "success_230623_115.jpg",
      show: "Home Decor 2023@QSNCC",
      showdate: "22 Fab 23 - 26 Feb 23",
    },
    {
      file: "success_230623_116.jpg",
      show: "Home Decor 2023@QSNCC",
      showdate: "22 Fab 23 - 26 Feb 23",
    },
    {
      file: "success_230623_117.jpg",
      show: "Furniture Living&Design 2023",
      showdate: "27 May 23 - 6 Jun 23",
    },
    {
      file: "success_230623_118.jpg",
      show: "Furniture Living&Design 2023",
      showdate: "27 May 23 - 6 Jun 23",
    },
    {
      file: "success_230623_119.jpg",
      show: "Furniture Living&Design 2023",
      showdate: "27 May 23 - 6 Jun 23",
    },
    {
      file: "success_230623_220.jpg",
      show: "Furniture Living&Design 2023",
      showdate: "27 May 23 - 6 Jun 23",
    },
    {
      file: "success_230623_221.jpg",
      show: "Furniture Living&Design 2023",
      showdate: "27 May 23 - 6 Jun 23",
    },
    {
      file: "success_230623_222.jpg",
      show: "Furniture Living&Design 2023",
      showdate: "27 May 23 - 6 Jun 23",
    },
    {
      file: "success_230623_223.jpg",
      show: "Furniture Living&Design 2023",
      showdate: "27 May 23 - 6 Jun 23",
    },
    {
      file: "success_230623_224.jpg",
      show: "Furniture Living&Design 2023",
      showdate: "27 May 23 - 6 Jun 23",
    },
    {
      file: "success_230623_225.jpg",
      show: "Furniture Living&Design 2023",
      showdate: "27 May 23 - 6 Jun 23",
    },
    {
      file: "success_230623_226.jpg",
      show: "Furniture Living&Design 2023",
      showdate: "27 May 23 - 6 Jun 23",
    },
    {
      file: "success_230623_227.jpg",
      show: "Furniture Living&Design 2023",
      showdate: "27 May 23 - 6 Jun 23",
    },
    {
      file: "success_230623_228.jpg",
      show: "Furniture Living&Design 2023",
      showdate: "27 May 23 - 6 Jun 23",
    },
    {
      file: "success_230623_229.jpg",
      show: "Bangkok Furniture Fair 2023",
      showdate: "4 Mar 23 - 12 Mar 23",
    },
    {
      file: "success_230623_330.jpg",
      show: "Bangkok Furniture Fair 2023",
      showdate: "4 Mar 23 - 12 Mar 23",
    },
    {
      file: "success_230623_331.jpg",
      show: "Bangkok Furniture Fair 2023",
      showdate: "4 Mar 23 - 12 Mar 23",
    },
    {
      file: "success_230623_332.jpg",
      show: "Bangkok Furniture Fair 2023",
      showdate: "4 Mar 23 - 12 Mar 23",
    },
    {
      file: "success_230623_333.jpg",
      show: "Bangkok Furniture Fair 2023",
      showdate: "4 Mar 23 - 12 Mar 23",
    },
    {
      file: "success_230623_334.jpg",
      show: "Bangkok Furniture Fair 2023",
      showdate: "4 Mar 23 - 12 Mar 23",
    },
    {
      file: "success_230623_335.jpg",
      show: "Bangkok Furniture Fair 2023",
      showdate: "4 Mar 23 - 12 Mar 23",
    },
    {
      file: "success_230623_336.jpg",
      show: "Bangkok Furniture Fair 2023",
      showdate: "4 Mar 23 - 12 Mar 23",
    },
  ]);
  return (
    <div className="oursuccess">
      <div>
        <div className="text-4xl md:text-5xl lg:text-6xl pt-8 text-center">
          ความสำเร็จที่ผ่านมา
        </div>
        <div className="flex justify-center py-4">
          <svg height={10} width={160}>
            <line
              x1="0"
              y1="0"
              x2="160"
              y2="0"
              style={{ stroke: "rgb(255,0,0)", strokeWidth: "5px" }}
            />
          </svg>
        </div>
      </div>
      <div className="success px-5 sm:px-10 container md:px-5">
        <div className="grid grid-cols-2 gap-2 sm:gap-6 md:grid-cols-4">
          {data.map((d, i) => (
            <div
              key={"success_pic_" + i}
              className="max-h-[300px] aspect-square relative overflow-hidden group "
            >
              <img
                src={
                  "https://worldfair.blob.core.windows.net/websuccess/" + d.file
                }
                alt={"success_pic_" + i}
                id={"success_pic_" + i}
                className="w-full h-full object-cover object-center"
              />

              <div className="absolute bottom-0 w-full px-2 py-2 bg-slate-200 bg-opacity-80 transition duration-300 translate-y-[105%] group-hover:translate-y-0">
                <div className="text-right text-[#ff0000] text-sm lg:text-base">
                  {d.show}
                </div>
                <div className="text-right text-[#ff0000] text-sm lg:text-base">
                  {d.showdate}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
