import Collection from "./components/solution/finance/collection/collection";
import CommReport from "./components/solution/finance/commReport/CommReport";
import CollectionHistory from "./components/solution/finance/collectionHistory/collectionHistory";

const AppRouteFinance = [
  {
    path: "finance/collection",
    element: <Collection />,
    show: [
      { dept: 1, acc: 1 },
      { dept: 6, acc: 1 },
    ],
  },
  {
    path: "finance/commissionreport",
    element: <CommReport />,
    show: [
      { dept: 1, acc: 1 },
      { dept: 6, acc: 1 },
    ],
  },
  {
    path: "finance/collectionhistory",
    element: <CollectionHistory />,
  },
];

export default AppRouteFinance;
